/** basic styling **/
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500;600;700&family=Knewave&display=swap');

:root {
	--fill-primary: RGB(20, 120, 255);
	--fill-primary-faded: RGB(20, 120, 255, 0.7);

	--fill-secondary: RGB(254, 204, 65);
	--fill-secondary-faded: RGBA(254, 204, 65, 0.6);

	--fill-neutral: RGB(255, 255, 255);

	--fill-error: RGB(255, 0, 0);
	--fill-success: RGB(0, 255, 124);

	--background-behind-error: RGB(255, 206, 206);
	--background-behind-success: RGB(208, 255, 231);
	--background-behind-secondary: RGB(255, 243, 212);

	--text-on-neutral-dark: RGB(0, 0, 0);
	--text-on-neutral-medium: RGBA(0, 0, 0, 0.87);
	--text-on-neutral-light: RGBA(0, 0, 0, 0.2);

	--text-on-primary-dark: RGB(255, 255, 255);
	--text-on-primary-medium: RGBA(255, 255, 255, 0.75);
	--text-on-primary-light: RGBA(255, 255, 255, 0.2);

	--text-on-error: RGB(255, 0, 0);
	--text-on-success: RGB(0, 195, 95);
	--font-family-body: Dosis;
	--font-family-heading: Knewave;
}

:root {
	--transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	--transition-duration: .28s;
	--animation-duration: .44s;
}

.initial {
	--transition-duration: 0s;
	--animation-duration: 0s;
}

@media (prefers-reduced-motion) {
	:root {
		--transition-duration: 0s;
		--animation-duration: 0s;
	}
}

:root {
	--header-index: 2;
	--between-content-and-header-index: 1;
	--behind-content-index: -1;
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']) {
	border-radius: 20px;
	background-color: var(--text-on-primary-dark);
	color: var(--fill-primary);
	height: 2.5rem;
	cursor: pointer;
	border: none;
	padding: 0 3rem;
	transition: background-color var(--transition-duration) var(--transition-timing-function);
	text-align: center;
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']):hover {
	background-color: var(--text-on-primary-medium);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset']):disabled, :where(a[type='button'])[aria-disabled='true'] {
	background-color: var(--text-on-primary-light);
	cursor: not-allowed;
}

@supports selector(:has(i)) {
	:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']):has(i) {
		padding: 0 1rem;
	}
}

@supports not selector(:has(i)) {
	:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']).icon {
		padding: 0 1rem;
	}
}

a[type='button'] {
	display: inline-block;
	text-decoration: none;
	line-height: 2.5rem;
	-webkit-appearance: none;
}

a:not([type='button']) {
	color: var(--text-on-primary-medium);
	text-decoration: none;
	transition: color var(--transition-duration) var(--transition-timing-function);
}

a:not([type='button']):hover {
	color: var(--text-on-primary-dark);
}

a:not([type='button'])[aria-disabled='true'] {
	color: var(--text-on-primary-light);
	cursor: not-allowed;
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']),
textarea,
select {
	appearance: none;
	background-image: none;
	border-radius: 0.5rem;
	background-color: var(--fill-neutral);
	padding: 0 1rem;
	height: 3rem;
	width: 100%;
	border: 1px solid var(--fill-neutral);
	color: black;
	transition:
		color var(--transition-duration) var(--transition-timing-function),
		border var(--transition-duration) var(--transition-timing-function),
		background-color var(--transition-duration) var(--transition-timing-function);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox'])[aria-invalid='true']:not(:focus),
select[aria-invalid='true']:not(:focus),
input[type='checkbox'][aria-invalid='true']:not(:focus) {
	background-color: var(--background-behind-error);
	border: 1px solid var(--fill-error);
	color: var(--text-on-error);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox'])[aria-invalid='true']:not(:focus)::placeholder,
select[aria-invalid='true']:not(:focus)::placeholder {
	color: var(--text-on-error);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']):disabled,
select:disabled,
input[type='checkbox']:disabled,
input[type='checkbox']:hover:disabled {
	border: 1px solid var(--text-on-primary-medium);
	color: var(--text-on-primary-medium);
	background-color: var(--text-on-primary-light);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']):disabled::placeholder,
select:disabled::placeholder {
	color: var(--text-on-primary-medium);
}

input[type='checkbox'] {
	appearance: none;
	margin-right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 4px;
	background-color: var(--fill-neutral);
	border: 1px solid transparent;
	cursor: pointer;
	transition:
		border var(--transition-duration) var(--transition-timing-function),
		background-color var(--transition-duration) var(--transition-timing-function),
}

input[type='checkbox']:hover,
input[type='checkbox']:checked {
	border: 1px solid var(--fill-secondary);
	background-color: var(--fill-secondary-faded);
}

input[type='checkbox'] {
	display: grid;
	place-content: center;
}

input[type='checkbox']::before {
	font-family: var(--font-family-icon);
	content: 'check';
	font-size: 0.8rem;
	color: var(--fill-primary);
	transform: scale(0);
	transition: transform var(--transition-duration) var(--transition-timing-function);
}

input[type='checkbox']:checked::before {
	transform: scale(1);
}

input[type='checkbox']:disabled {
	cursor: not-allowed;
}

label {
	transition: color var(--transition-duration) var(--transition-timing-function);
	white-space: pre-line;
}

label input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']),
label select {
	margin-top: .5rem;
	display: block;
}

@supports selector(:has(input)) {
	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)),
	label:has(select[aria-invalid='true']:not(:focus)),
	label:has(select[aria-invalid='true']:not(:focus)):after {
		color: var(--text-on-error);
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox'])),
	label:has(select) {
		display: inline-block;
		width: 100%;
		padding-bottom: 2rem;
	}
}

@supports not selector(:has(input)) {
	label[aria-invalid='true']:not(:focus-within),
	label[aria-invalid='true']:not(:focus-within):after {
		color: var(--text-on-error);
	}

	label {
		display: inline-block;
		width: 100%;
		padding-bottom: 2rem;
	}
}

label input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']) + p,
label select + p {
	position: absolute;
	margin: 0;
}

label p {
	margin: 0;
}

@supports selector(:has(input)) {
	label:has(input[type='checkbox']) {
		display: flex;
		align-items: center;
		padding-bottom: 1.6875rem;
	}

	label:has(input[type='checkbox'][aria-invalid='true']:not(:focus)) {
		padding-bottom: 0;
	}

	label:has(input[type='checkbox'][aria-invalid='true']:not(:focus)) p:last-of-type {
		flex-basis: 100%;
		margin: 0;
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)) a:not([type='button']) {
		color: var(--text-on-error);
	}

	label:has(select):after {
		content: '\e903';
		font-family: var(--font-family-icon);
		font-size: 1rem;
		color: var(--text-on-neutral-light);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: -3rem;
		padding: 0 1rem;
		width: 100%;
		height: 3rem;
		pointer-events: none;
	}

	label:has(select:disabled):after {
		color: var(--text-on-primary-medium);
	}
}

@supports not selector(:has(input)) {
	label.checkbox {
		display: flex;
		align-items: center;
		padding-bottom: 1.6875rem;
	}

	label.checkbox[aria-invalid='true']:not(:focus-within) {
		padding-bottom: 0;
	}

	label.checkbox[aria-invalid='true']:not(:focus-within) p:last-of-type {
		flex-basis: 100%;
		margin: 0;
	}

	label[aria-invalid='true']:not(:focus-within) a:not([type='button']) {
		color: var(--text-on-error);
	}

	label.select::after {
		content: 'chevron down';
		font-family: var(--font-family-icon);
		font-size: 1rem;
		color: var(--text-on-neutral-light);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: -3rem;
		padding: 0 1rem;
		width: 100%;
		height: 3rem;
		pointer-events: none;
	}

	label.select.disabled::after {
		color: var(--text-on-primary-medium);
	}
}

body {
	background-color: var(--fill-primary);
	color: var(--text-on-primary-dark);
}

p, span, details, summary {
	font-family: var(--font-family-body);
	font-size: 1.125rem;
	font-weight: 500;
	letter-spacing: 0.25px;
}

h1 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-heading);
	font-size: 3.75rem;
	font-weight: 400;
	letter-spacing: -1.28px;
	line-height: 4.25rem;
}

h2 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-body);
	font-size: 2.5rem;
	font-weight: 700;
	letter-spacing: 0.55px;
}

h3 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-body);
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: 0.33px;
}

h4 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-body);
	font-size: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.33px;
}

p strong {
	font-weight: 700;
}

h1 strong, h2 strong, h3 strong {
	color: var(--fill-secondary);
}

input:not([type='button'], [type='submit'], [type='reset']), select {
	color: var(--text-on-neutral-dark);
	font-family: var(--font-family-body);
	font-size: 1.125rem;
	font-weight: 600;
	letter-spacing: 0.25px;
}

input::placeholder {
	color: var(--text-on-neutral-light);
	transition: color var(--transition-duration) var(--transition-timing-function);
}

:where(label, a, button, input[type='button'], input[type='submit'], input[type='reset']) {
	font-family: var(--font-family-body);
	font-weight: 700;
	font-size: 1rem;
	letter-spacing: 0.22px;
}

label {
	color: var(--text-on-primary-medium);
}


/** END BASIC STYLING**/

body {
  margin: 0;
  padding: 0;
  background: url('./assets/background-illustration.png') no-repeat center center fixed;
  background-size: cover;
}

.container {
  background-image: var(--background-image);
  background-size: cover;
  background-repeat: repeat;

  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: 100%;

  justify-content: center;
  align-items: center;
}

.content {
  width: 80vw;
  max-width: 78rem;
}

.innercontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  height: 100%;
}

.innercontainer section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.innercontainer h1 {
  grid-column: span 6;
  transform: rotate(-7deg);
  margin: 4rem 4rem 0 4rem;
  pointer-events: none;
}


.innercontainer .form {
  grid-column: 2 / span 4;

  display: grid;
  height: fit-content;
  grid-template-rows: [input-start] auto [input-end register-start] auto [register-end login-start] auto [login-end];
  gap: 1rem;
}

.five {
  transform: scale(1.4);
  pointer-events: none;
}

@media screen and (max-width: 1280px) {
  .innercontainer h1 {
    margin: 4rem 0;
  }

  .innercontainer .form {
    grid-column: span 6;
  }
}

@media screen and (max-width: 1112px) {
  .innercontainer img {
    max-width: 151%;
  }
}

@media screen and (max-width: 1024px) {
  .innercontainer .form {
    margin-top: -3rem;
  }
}

@media screen and (max-width: 800px) {
  body::before {
    display: none;
  }

  .innercontainer img {
    display: none;
  }

  .innercontainer section {
    grid-column: span 2;
  }

  .innercontainer h1 {
    text-align: center;
  }

  .innercontainer .form {
    grid-column: 2 / span 4;
    margin-top: 0rem;
  }
}

@media screen and (max-width: 500px) {
  #root {
    width: calc(100vw - 2rem);
  }

  .innercontainer .form {
    grid-column: span 6;
  }
}

.user {
  text-decoration: inherit;
  /* no underline */
	background-color: var(--text-on-primary-dark);
	color: var(--fill-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;
  border-radius: 25px;
}

.user-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.user a {
  text-decoration: inherit;
	color: var(--fill-primary);
  /* no underline */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.user a:hover {
	color: var(--fill-primary);
}

.user:hover {
  background-color: var(--text-on-primary-medium);
}

.user-icon {
  font-size: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  text-align: center;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
}

.user-email {
  font-size: 14px;
}

.user-forget {
  font-size: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}